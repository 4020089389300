<template>
  <v-row>
    <v-col md="12">
      <v-card>
        <v-card-title>
          <!-- <v-icon
            @click="pushRouteToView('clients')"
            class="mr-4"
            style="cursor: pointer"
            title="voltar"
          >
            mdi-arrow-left
          </v-icon>-->
          <v-btn small @click="pushRouteToView('clients')" class="mr-4 btn-back">
            <v-icon>
              mdi-arrow-left
            </v-icon>
            Voltar
          </v-btn>
          <v-icon class="mr-2">mdi-store</v-icon>
          Adicionar Client
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="createClient" @submit.prevent="newClient" v-model="createClient">
            <v-row>
              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field v-model="client.cnpj" label="CNPJ" :rules="cnpjRules" v-mask="['##.###.###/####-##']"
                  outlined dense append-icon="mdi-card-account-details-outline" />
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field v-model="client.companyName" :rules="textRules" label="Razão Social" outlined dense
                  append-icon="mdi-office-building" v-uppercase />
              </v-col>

              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field v-model="client.fantasyName" :rules="textRules" label="Nome Fantasia" outlined dense
                  append-icon="mdi-office-building" v-uppercase />
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field v-model="client.phone" v-mask="['(##) ####-####', '(##) #####-####']" :rules="textRules"
                  label="Telefone" outlined dense append-icon="mdi-phone" />
              </v-col>

              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field v-model="client.email" :rules="textRules" label="E-mail" outlined dense
                  append-icon="mdi-at" />
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field v-model="client.zipcode" id="cep" name="cep" type="text" v-mask="['#####-###']"
                  label="CEP" :rules="textRules" outlined @keyup="fillCep" dense append-icon="mdi-map-marker" />
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="9" lg="9" md="9" sm="12">
                <v-text-field v-model="client.street" label="Endereço" :rules="textRules" outlined dense
                  append-icon="mdi-map-marker" v-uppercase />
              </v-col>
              <v-col xl="3" lg="3" md="3" sm="12">
                <v-text-field v-model.number="client.number" type="number" :rules="textRules" label="Número" outlined
                  dense append-icon="mdi-map-marker" />
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field v-model="client.addressComplement" label="Complemento" outlined dense
                  append-icon="mdi-map-marker" v-uppercase />
              </v-col>

              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field v-model="client.district" label="Bairro" :rules="textRules" outlined dense
                  append-icon="mdi-map-marker" v-uppercase />
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field v-model="client.city" label="Cidade" :rules="textRules" outlined dense
                  append-icon="mdi-map-marker" v-uppercase />
              </v-col>

              <v-col xl="6" lg="6" md="6" sm="12">
                <v-select v-model="client.state" :items="states" :rules="textRules" label="Estado" outlined
                  item-text="state" item-value="acronym" dense append-icon="mdi-map-marker" v-uppercase></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field v-model="client.password" :rules="textRules" type="password" label="Senha"
                  placeholder="Senha" outlined dense append-icon="mdi-lock" />
              </v-col>

              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field v-model="confirmPassword" type="password" label="Confirmar Senha" :rules="passwordRules"
                  placeholder="Repita a Senha" outlined dense append-icon="mdi-lock" />
              </v-col>
            </v-row>

            <v-row>
              <v-col md="12" class="text-right mr-4">
                <v-col sm="12">
                  <v-btn type="submit" color="success">
                    <v-icon left>
                      mdi-content-save
                    </v-icon>
                    Salvar
                  </v-btn>
                </v-col>
              </v-col>
            </v-row>

          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { validarCNPJ } from "@/common/cnpj.service";
import { mask } from "vue-the-mask";
import store from "@/store";
import { CHANGE_PROGRESS } from "@/store/mutations/mutations.type";
import { CLIENT_CREATE, CLIENT_GET } from "@/store/actions/client.type";
import { CLIENT_RESET } from "@/store/mutations/client.type";
import { getCep } from "@/services.js";

export default {
  name: "clientCreate",

  directives: { mask },

  data: () => ({
    createClient: false,

    dataCep: {
      cep: "",
      rua: "",
      bairro: "",
      cidade: "",
      estado: "",
    },

    confirmPassword: "",

    cnpjRules: [
      v => !!v || "O CNPJ é necessário",
      v => validarCNPJ(v) || "Informe um CNPJ válido."
    ],
    passwordRules: [
      v => !!v || "Este campo é obrigatório",
      v => {
        if (store.getters.client.password !== v) {
          return "Senha e confirmação tem que ser iguais.";
        }
        return true;
      }
    ],
    emailRules: [
      //v => !!v || "E-mail é obrigatório",
      value => {
        if (typeof value !== "undefined" && value !== null) {
          if (value.length > 0) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Informe um email válido";
          }
        }
        return false;
      }
    ],

    textRules: [
      v => !!v || "Este campo é obrigatório"
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ],

    states: [
      { acronym: "AC", state: "ACRE" },
      { acronym: "AL", state: "ALAGOAS" },
      { acronym: "AP", state: "AMAPÁ" },
      { acronym: "AM", state: "AMAZONAS" },
      { acronym: "BA", state: "BAHIA" },
      { acronym: "CE", state: "CEARÁ" },
      { acronym: "DF", state: "DISTRITO FEDERAL" },
      { acronym: "ES", state: "ESPÍRITO SANTO" },
      { acronym: "GO", state: "GOIÁS" },
      { acronym: "MA", state: "MARANHÃO" },
      { acronym: "MT", state: "MATO GROSSO" },
      { acronym: "MS", state: "MATO GROSSO DO SUL" },
      { acronym: "MG", state: "MINAS GERAIS" },
      { acronym: "PA", state: "PARÁ" },
      { acronym: "PB", state: "PARAÍBA" },
      { acronym: "PR", state: "PARANÁ" },
      { acronym: "PE", state: "PERNAMBUCO" },
      { acronym: "PI", state: "PIAUÍ" },
      { acronym: "RJ", state: "RIO DE JANEIRO" },
      { acronym: "RN", state: "RIO GRANDE DO NORTE" },
      { acronym: "RS", state: "RIO GRANDE DO SUL" },
      { acronym: "RO", state: "RONDÔNIA" },
      { acronym: "RR", state: "RORAIMA" },
      { acronym: "SC", state: "SANTA CATARINA" },
      { acronym: "SP", state: "SÃO PAULO" },
      { acronym: "SE", state: "SERGIPE" },
      { acronym: "TO", state: "TOCANTINS" }
    ]
  }),

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(CLIENT_RESET);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    await store.commit(CLIENT_RESET);

    if (to.params.id !== undefined) {
      await store.dispatch(CLIENT_GET, to.params.id);
    }

    return next();
  },

  methods: {
    pushRouteToView(route) {
      this.$router.push({ name: route });
    },

    fillCep() {
      const cep = this.client.zipcode.replace(/\D/g, "");
      if (cep.length === 8) {
        getCep(cep).then((response) => {
          this.client.street = response.data.logradouro;
          this.client.district = response.data.bairro;
          this.client.state = response.data.uf;
          this.client.city = response.data.localidade;
        });
      }
    },

    newClient() {
      if (this.$refs.createClient.validate()) {
        this.$store.commit(CHANGE_PROGRESS, true);
        this.$store
          .dispatch(CLIENT_CREATE)
          .then(res => {
            this.$store.commit(CHANGE_PROGRESS, false);
            this.snackbar.snackbar = true;
            this.snackbar.color = "success";
            this.snackbar.text = "Adicionado com sucesso";

            if (res.data.id) {
              this.$router.push({
                name: "client",
                params: { id: res.data.id }
              });
            } else {
              this.$router.push({
                name: "clients"
              });
            }
          })
          .catch(err => {
            if (err.response.data.code == 48) {
              this.$store.commit(CHANGE_PROGRESS, false);
              this.snackbar.snackbar = true;
              this.snackbar.color = "error";
              this.snackbar.text = "CNPJ já cadastrado";
            } else {
              this.$store.commit(CHANGE_PROGRESS, false);
              this.snackbar.snackbar = true;
              this.snackbar.color = "error";
              this.snackbar.text = "Erro ao criar Cliente";
            }
          });
      } else {
        this.snackbar.snackbar = true;
        this.snackbar.color = "error";
        this.snackbar.text = "Preencha o formulário corretamente";
      }
    }
  },

  computed: {
    ...mapGetters(["snackbar", "client"])
  }
};
</script>
